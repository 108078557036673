import { useTranslation } from 'react-i18next';

export const useTranslations = () => {
    const { t } = useTranslation();
    return {
        messages: {
            invoiceRowsNotMatch: {
                title: t('component.invoiceDetailsHeader.notification.invoiceRowsNotMatch.title'),
                text: t('component.invoiceDetailsHeader.notification.invoiceRowsNotMatch.message'),
            },
            invoiceIsDuplicate: {
                title: t('component.invoiceDetailsHeader.notification.invoiceIsDuplicate.title'),
                text: t('component.invoiceDetailsHeader.notification.invoiceIsDuplicate.message'),
            },
            notMatchAndDuplicate: {
                title: t('component.invoiceDetailsHeader.notification.notMatchAndDuplicate.title'),
                text: t('component.invoiceDetailsHeader.notification.notMatchAndDuplicate.message'),
            },
        },
    };
};
