import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createDataId } from '../../../../common/utils/dataId';
import { AccountDTO, TransactionRowDimensionDTO, TransactionRowDTO } from '../../../../services/types/ApiTypes';
import { MainPage, MainPageType } from '../../../../components/MainPage/MainPage';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../../components/ContentBlock/ContentBlockHeader';
import { Typography } from '../../../../components/Ui/Typography';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { DataGrid, DataGridColumnOptions } from '../../../../components/Ui/DataGrid/DataGrid';
import { getInvoiceIssues } from '../common/helpers';
import {
    createColumns,
    createNewUnpostedAmountRow,
    createRowObject,
    dataId,
    defaultValidationFields,
    getTransactionsRowDimensions,
    messages,
    rootClassName,
    validateTransactionRow,
} from './TransactionRowsHelper';
import { DataGridColumn } from '../../../../components/Ui/DataGrid/DataGrid/components/DataGridColumn';
import { Checkbox } from '../../../../components/Ui/Inputs/Checkbox';
import { TableRowActions } from '../../../../components/Ui/DataGrid/layout/TableRowActions';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../components/ContentBlock/ContentBlockFooter';
import { DisplayData } from '../../../../components/Ui/DisplayData';
import { trimAfterComma } from '../invoice-rows/InvoiceRowsHelper';
import { Button } from '../../../../components/Ui/Button';
import Icon, { ICONS } from '../../../../components/Icon/Icon';
import { ExpandedRowContent } from './components/ExpandedRowContent/ExpandedRowContent';
import { TransactionRowsProps, TransactionRowTableObject, TransactionRowValidationFields } from './TransactionRowsTypes';
import { formatMoneyToShowSeparators } from '../invoice-header/utils';
import { isAuthorized, isSystemSetting, Role } from '../../../../common/user/userPermissionUtil';
import useIsMobile from '../../../../common/hooks/useIsMobile';
import { ProductItemType } from './components/ExpandedRowContent/ExpandedRowContentType';
import { TransactionRowDetails } from './components/TransactionRowDetails';
import { useCompanySettings } from '../../../../common/hooks/useCompanySettings';
import { isPenultimateConfirmer } from '../invoice-confirmations/InvoiceConfirmationsHelper';
import { CompanySettingStatus } from '../../../settings/company-settings/components/Settings/CompanySettingsListHelper';
import { HeaderNotification } from '../common/HeaderNotification/HeaderNotification';

import './TransactionRows.scss';

const TransactionRows: React.FC<TransactionRowsProps> = ({
    invoice,
    reloadInvoiceData,
    addNewTransactionRow,
    getTransactionRowsList,
    getUserVATCodes,
    updateCustomCostObjectiveArray,
    updateCustomField,
    updateAccount,
    updateVatCode,
    updateTransactionRowProductItemBuyer,
    transactionRowsLoadable,
    customCostObjectives,
    invalidTransactionRows,
    userActiveVatCodes,
    openTransactionRowDetails,
    transactionRowDetails,
    transactionRowsIsLoading,
}) => {
    const [transactionRowsList, setTransactionRowsList] = React.useState<TransactionRowDTO[]>([]);
    const [page, setPage] = React.useState<number>(1);
    const isMobile = useIsMobile();

    const { t } = useTranslation();

    const { IsTransactionRowsCheckEnabled, IsTransactionRowsPenultimateCheckEnabled, IsVatSumDeviationWarningEnabled } = useCompanySettings();

    const isProducItemModuleActive = useMemo(() => isSystemSetting('IsProductItemsModulActive'), []);

    const isUnPostedAmountNotificationVisible = useMemo(() => invoice?.CanEditInvoice && invoice.TotalAmountWithVat - invoice.TransactionRowsTotalSumRemaining !== 0, [invoice]);

    const isVatSumDeviationNotificationVisible = useMemo(() => IsVatSumDeviationWarningEnabled === CompanySettingStatus.Enabled && invoice?.Vat - invoice?.TransactionRowsVAT !== 0, [
        IsVatSumDeviationWarningEnabled,
        invoice,
    ]);

    const unPostedAmountValue = useMemo(() => (invoice?.TotalAmountWithVat - invoice?.TransactionRowsTotalSumRemaining).toFixed(2), [invoice]);

    const vatSumDeviationValue = useMemo(() => (invoice?.Vat - invoice?.TransactionRowsVAT).toFixed(2), [invoice]);

    const isRowsCheckEnabled = IsTransactionRowsCheckEnabled === CompanySettingStatus.Enabled;

    const isRowsPenultimateCheckEnabled = IsTransactionRowsPenultimateCheckEnabled === CompanySettingStatus.Enabled;

    const isInvoicePenultimateConfirmer = useMemo(() => isPenultimateConfirmer(invoice), [invoice]);

    const defaultColumns = useMemo(() => createColumns(isMobile, t), [isMobile]);

    const isReadOnly = useMemo(() => !invoice?.canConfirmCurrentTaskProp || !isAuthorized(Role.CanUpdateInvoice), [invoice?.canConfirmCurrentTaskProp]);

    const isLoading = transactionRowsLoadable?.loading || transactionRowsIsLoading;

    const tableItems = useMemo((): TransactionRowTableObject[] => {
        const result = [];

        for (const row of transactionRowsList) {
            result.push(createRowObject(row));
        }
        return result;
    }, [transactionRowsList, isMobile, invalidTransactionRows]);

    const handleCustomFieldChange = useCallback((dimension: TransactionRowDimensionDTO, row: TransactionRowDTO) => {
        updateCustomField(dimension, row);
    }, []);

    const handleAccountFieldChange = useCallback((account: AccountDTO, row: TransactionRowDTO) => {
        updateAccount(account, row);
    }, []);

    const handleBuyerChange = useCallback((productItem: ProductItemType, row: TransactionRowDTO) => {
        updateTransactionRowProductItemBuyer(productItem, row);
    }, []);

    const handleVatChange = useCallback((row: TransactionRowDTO) => {
        updateVatCode(row, reloadInvoiceData);
    }, []);

    const handleAddNewRow = () => {
        const newRow = createNewUnpostedAmountRow(invoice, t);
        addNewTransactionRow(newRow, reloadInvoiceData);
    };

    const getValidationFields = useCallback(
        (row: TransactionRowDTO): TransactionRowValidationFields => {
            return invalidTransactionRows.includes(row.Id)
                ? validateTransactionRow(invoice, row, userActiveVatCodes, isRowsCheckEnabled, isRowsPenultimateCheckEnabled, isInvoicePenultimateConfirmer)
                : defaultValidationFields;
        },
        [invalidTransactionRows],
    );

    const emptyError = useMemo(() => {
        const errors = getInvoiceIssues(invoice);
        return errors.isRowsNotMatchAndDuplicate
            ? t(messages.notMatchAndDuplicate)
            : errors.isDuplicate
            ? t(messages.invoiceIsDuplicate)
            : errors?.isRowsNotMatch
            ? t(messages.invoiceRowsNotMatch)
            : t(messages.createTransactionRowsManually);
    }, [invoice]);

    const renderCheckBox = useCallback(() => {
        return (
            <TableRowActions>
                <Checkbox />
            </TableRowActions>
        );
    }, []);

    const renderExpandedRowContent = useCallback(
        (index: number) => {
            return (
                <ExpandedRowContent
                    dataId={createDataId(dataId, 'expanded-row', transactionRowsList[index].Id)}
                    isReadOnly={isReadOnly}
                    invoiceStatus={invoice.Status}
                    accountingDate={invoice?.AccountingDate}
                    data={transactionRowsList[index]}
                    validationFields={getValidationFields(transactionRowsList[index])}
                    isMobile={isMobile}
                    isProducItemModuleActive={isProducItemModuleActive}
                    onCustomFieldChange={handleCustomFieldChange}
                    onAccountFieldChange={handleAccountFieldChange}
                    onBuyerChange={handleBuyerChange}
                    onVatChange={handleVatChange}
                />
            );
        },
        [tableItems],
    );

    const renderRowCellContent = useCallback(
        (row: TransactionRowDTO) => {
            return (
                <div className={`${rootClassName}__cell`}>
                    <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'description')} className={`${rootClassName}__cell-desc`} variant="body-md" element="div">
                        {row.Description}
                    </Typography>
                    <div data-id={createDataId(dataId, row.OrderNo, 'cell')} className={`${rootClassName}__cell-sums`}>
                        <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'net')} className={`${rootClassName}__cell-sum`} variant="body-md" element="span">
                            {row.SumWithoutVat}
                        </Typography>
                        <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'vat')} className={`${rootClassName}__cell-sum`} variant="body-md" element="span">
                            {row.VAT}
                        </Typography>
                        <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'total')} className={`${rootClassName}__cell-sum`} variant="body-md" element="span">
                            {row.Total}
                        </Typography>
                    </div>
                </div>
            );
        },
        [tableItems],
    );

    const renderActionsCellContent = useCallback(() => {
        return (
            <Button iconButton variant="text" size="sm">
                <Icon iconName={ICONS.EDIT_24} />
            </Button>
        );
    }, []);

    const renderDescription = (item: TransactionRowTableObject) => {
        return (
            <div className={`${rootClassName}__cell__description-container`}>
                <Typography variant="body-md" element="div">
                    {item.Description}
                </Typography>
                <div className={`${rootClassName}__cell__description-container__icons`}>
                    {item?.Comment?.length && (
                        <div className={`${rootClassName}__cell__description-container__icons__icon`}>
                            <Icon iconName={ICONS.UI_ICON_COMMENT} />
                        </div>
                    )}
                    {item?.AccountingDate?.toString()?.length && (
                        <div className={`${rootClassName}__cell__description-container__icons__icon`}>
                            <Icon iconName={ICONS.UI_ICON_CALENDAR} />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getDefaultColumnProps = useCallback(
        (column: DataGridColumnOptions) => {
            const props = {
                ...column,
            };

            if (column.columnName.includes('Description')) {
                props.renderCellContent = renderDescription;
            }

            if (isMobile) {
                props.width = 100;
                props.renderCellContent = renderRowCellContent;
            }

            return props;
        },
        [isMobile],
    );

    const handleLoadMore = () => {
        const totalPage = Math.ceil(transactionRowsLoadable.payload?.TotalCount / transactionRowsLoadable.payload?.Take);
        if (isLoading || totalPage === page) {
            return;
        }
        const nextPage = page + 1;
        getTransactionRowsList(invoice.Id, nextPage);
        setPage(nextPage);
    };

    const handleClickRow = (item: TransactionRowTableObject) => {
        openTransactionRowDetails(item);
    };

    useEffect(() => {
        if (transactionRowsLoadable?.payload?.Items && customCostObjectives?.length > 0 && invoice?.Id) {
            setTransactionRowsList(getTransactionsRowDimensions(transactionRowsLoadable.payload.Items, customCostObjectives));
        }
    }, [transactionRowsLoadable?.payload, customCostObjectives]);

    useEffect(() => {
        if (invoice?.AccountingDate) {
            updateCustomCostObjectiveArray(invoice?.AccountingDate.toString());
        }
    }, [invoice?.AccountingDate]);

    useEffect(() => {
        if (invoice?.Id) {
            setPage(1);
            setTransactionRowsList([]);
            getTransactionRowsList(invoice.Id, 1);
            getUserVATCodes();
        }
    }, [invoice?.Id]);

    useEffect(() => {
        const shouldRefetchTRs = transactionRowDetails.isDetailsUpdated && !transactionRowDetails?.isOpen;
        if (shouldRefetchTRs) {
            reloadInvoiceData(invoice.Id);
            getTransactionRowsList(invoice.Id, 1);
        }
    }, [invoice?.Id, transactionRowDetails.isDetailsUpdated, transactionRowDetails.isOpen]);

    const title = `${t('component.transactionRows.heading')} (${transactionRowsLoadable.payload?.TotalCount || 0})`;
    const rowsNet = formatMoneyToShowSeparators(trimAfterComma(invoice?.TransactionRowsPrice.toString(), 4, 2));
    const rowsVat = formatMoneyToShowSeparators(trimAfterComma(invoice?.TransactionRowsVAT.toString(), 4, 2));
    const rowsTotal = formatMoneyToShowSeparators(trimAfterComma(invoice?.TransactionRowsTotal.toString(), 4, 2));

    if (!transactionRowsList?.length) {
        return (
            <ContentBlock>
                <ContentBlockHeader
                    className={`${rootClassName}__header`}
                    dataId={createDataId(dataId, 'header')}
                    type={ContentBlockHeaderType.TABLE_HEADER}
                    title={
                        <Typography variant="h1" element="span">
                            {t('component.transactionRows.heading')}
                        </Typography>
                    }
                />
                <ContentBlockBody dataId={createDataId(dataId, 'content-block__empty-transaction-rows')} className={`${rootClassName}__empty-tr-error-content`}>
                    <Typography variant="body-md">{emptyError}</Typography>
                </ContentBlockBody>
            </ContentBlock>
        );
    }

    return (
        <MainPage className={rootClassName} type={MainPageType.WIDE}>
            <TransactionRowDetails invoiceStatus={invoice.Status} isProductItemModuleActive={isProducItemModuleActive} />
            <ContentBlock>
                <ContentBlockHeader
                    className={`${rootClassName}__header`}
                    dataId={createDataId(dataId, 'header')}
                    type={ContentBlockHeaderType.TABLE_HEADER}
                    title={
                        <Typography variant="h1" element="span">
                            {title}
                        </Typography>
                    }
                />
                <ContentBlockBody className={`${rootClassName}__table-content`} dataId={createDataId(dataId, 'contentBlockBody')}>
                    {isUnPostedAmountNotificationVisible && (
                        <HeaderNotification
                            type="alert"
                            dataId={createDataId(dataId, 'unposted-amount')}
                            disabled={isLoading}
                            title={`${t('component.transactionRows.unpostedAmount')}: ${unPostedAmountValue} ${invoice.Currency}`}
                            buttonText={t('component.transactionRows.unpostedAmount.button')}
                            onClick={handleAddNewRow}
                        />
                    )}
                    {isVatSumDeviationNotificationVisible && (
                        <HeaderNotification
                            type="alert"
                            dataId={createDataId(dataId, 'vat-deviation')}
                            title={`${t('component.transactionRows.vatSumDeviationAmount')}: ${vatSumDeviationValue} ${invoice.Currency}`}
                        />
                    )}
                    <div className={`${rootClassName}__table`}>
                        <DataGrid
                            items={tableItems}
                            renderExpandedRowContent={renderExpandedRowContent}
                            isLoading={isLoading}
                            height={1000}
                            onLoadMore={handleLoadMore}
                            isExpandable
                            isDefaultExpanded
                            isWidthFixed
                            onClickRow={handleClickRow}
                        >
                            <DataGridColumn
                                key={invoice.Id}
                                renderCellContent={renderCheckBox}
                                actionsColumn
                                columnName="bulk-button"
                                cellProps={{ justify: 'center' }}
                                headCellProps={{ justify: 'center' }}
                                renderHeadCellContent={renderCheckBox}
                            />
                            {defaultColumns.map((column) => (
                                <DataGridColumn key={column.columnName} {...getDefaultColumnProps(column)} />
                            ))}

                            <DataGridColumn actionsColumn columnName="action-buttons" cellProps={{ justify: 'end' }} headCellProps={{ justify: 'end' }} renderCellContent={renderActionsCellContent} />
                        </DataGrid>
                    </div>
                </ContentBlockBody>
                <ContentBlockFooter contentClass={`${rootClassName}__footer`} type={ContentBlockFooterType.PAGER}>
                    <Typography className={`${rootClassName}__footer-info`} dataId={createDataId('footer', 'info')} variant="body-md" element="div">
                        {t('component.transactionRows.footer.info')}
                    </Typography>
                    <div className={`${rootClassName}__footer-sums`} data-id={createDataId(dataId, 'footer', 'sums')}>
                        <DisplayData dataId={createDataId(dataId, 'footer', 'sum', 'sum')} label={t('component.transactionRows.footer.sum')} value={rowsNet} justifyContent="end" size="sm" />
                        <DisplayData dataId={createDataId(dataId, 'footer', 'sum', 'vat')} label={t('component.transactionRows.footer.vat')} value={rowsVat} justifyContent="end" size="sm" />
                        <DisplayData
                            dataId={createDataId(dataId, 'footer', 'sum', 'total')}
                            label={t('component.transactionRows.footer.grandTotal')}
                            value={rowsTotal}
                            justifyContent="end"
                            size="sm"
                        />
                    </div>
                </ContentBlockFooter>
            </ContentBlock>
        </MainPage>
    );
};

export default TransactionRows;
