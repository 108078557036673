import React from 'react';
import { Formik } from 'formik';

import Modal from '../../../../../../components/Modal/Modal';
import { Button } from '../../../../../../components/Ui/Button';
import { Typography } from '../../../../../../components/Ui/Typography';
import { DropdownMenu } from '../../../../../../components/DropdownMenu/DropdownMenu';
import Icon, { ICONS } from '../../../../../../components/Icon/Icon';
import { Textarea } from '../../../../../../components/Ui/Inputs/Textarea';
import { TextInput } from '../../../../../../components/Ui/Inputs/TextInput';
import { useMonetaryScalesFormat } from '../../../../../../components/MonetaryPrecisionScales/hooks/useMonetaryScalesFormat';
import { NumericInput } from '../../../../../../components/Ui/Inputs/NumericInput';
import { Tooltip } from '../../../../../../components/Ui/Tooltip';
import { InputLabel } from '../../../../../../components/Ui/Inputs/common/InputLabel';
import CalendarDatePicker from '../../../../../../components/CalendarDatePicker/CalendarDatePicker';
import { CircularProgress } from '../../../../../../components/CircularProgress';

import { CONSTANTS } from './helpers';
import { useValidationSchema, useDataId, useTransactionRowDetails, useClasses, useTranslations } from './hooks';
import { TransactionRowDetailsProps } from './TransactionRowDetailsTypes';

export const TransactionRowDetailsView: React.FC<TransactionRowDetailsProps> = (props) => {
    const { invoiceStatus, isProductItemModuleActive } = props;
    const t = useTranslations();
    const classes = useClasses({ isProductItemModuleActive });
    const monetaryScales = useMonetaryScalesFormat();
    const validationSchema = useValidationSchema();
    const dataId = useDataId('transaction-row-details-modal');
    const {
        handleCloseModal,
        handleSubmitForm,
        initialValues,
        getNumericInputProps,
        getTextInputProps,
        isVatEditable,
        isShowCommentField,
        handleClickAddCommentButton,
        handleClickAddAccountingDateButton,
        isShowAccountingDateField,
        isShowAccountingDateAndCommentSection,
        transactionRowDetails,
        isGeneralFieldsEditAllowed,
    } = useTransactionRowDetails({
        invoiceStatus,
    });

    return (
        <Modal isOpen={transactionRowDetails.isOpen} dataId={dataId?.root} hideCloseButton className={classes.root} onClose={handleCloseModal}>
            <div className={classes.header.root}>
                <Typography variant="h3">{t.modalTitle}</Typography>

                <div className={classes.header.actions}>
                    {isGeneralFieldsEditAllowed && (
                        <>
                            {!isShowCommentField && (
                                <Button variant="text" size="sm" startIcon={<Icon iconName={ICONS.COMMENT_24} />} onClick={handleClickAddCommentButton}>
                                    {t.addCommentButton}
                                </Button>
                            )}
                            {!isShowAccountingDateField && (
                                <Button variant="text" size="sm" startIcon={<Icon iconName={ICONS.CALENDAR_24} />} onClick={handleClickAddAccountingDateButton}>
                                    {t.addAccountingDateButton}
                                </Button>
                            )}
                        </>
                    )}
                    <DropdownMenu dataId="ttt" items={[]}>
                        <Button variant="text" endIcon={<Icon iconName={ICONS.CHEVRON_DOWN_24} />} dataId={''} size="sm" disabled>
                            {t.moreActionsButton}
                        </Button>
                    </DropdownMenu>
                </div>

                <Button variant="outlined" iconButton size="sm" onClick={handleCloseModal}>
                    <Icon iconName={ICONS.CLOSE_24} />
                </Button>
            </div>
            <Formik initialValues={initialValues} onSubmit={handleSubmitForm} validationSchema={validationSchema} validateOnChange>
                {(formik) => (
                    <>
                        <div className={classes.form.fields.root}>
                            <Textarea {...getTextInputProps(formik, 'Description')} required max={CONSTANTS.MAX_DESCRIPTION_LENGTH} />

                            {isShowAccountingDateAndCommentSection && (
                                <div className={classes.form.fields.commentAndAccountingDate}>
                                    {isShowCommentField && <Textarea {...getTextInputProps(formik, 'Comment')} max={CONSTANTS.MAX_COMMENT_LENGTH} />}
                                    {isShowAccountingDateField && (
                                        <div className={classes.form.fields.createFieldName('AccountingDate')}>
                                            <InputLabel variant="label-md" disabled={!isGeneralFieldsEditAllowed}>
                                                {t.fields.AccountingDate.label}
                                            </InputLabel>
                                            <CalendarDatePicker
                                                value={formik.values.AccountingDate}
                                                dataId={dataId?.createFieldName('AccountingDate')}
                                                name="AccountingDate"
                                                wrapperClass={classes.form.fields.createFieldName('AccountingDate')}
                                                disabled={!isGeneralFieldsEditAllowed}
                                                inputProps={{
                                                    icon: ICONS.CALENDAR_24,
                                                }}
                                                onChange={(date) => {
                                                    formik.setFieldValue('AccountingDate', date);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={classes.form.fields.sumAndVat}>
                                <NumericInput {...getNumericInputProps(formik, 'SumWithoutVat', monetaryScales.netVatTotal, CONSTANTS.FIXED_SCALES_VALUES.SumWithoutVat)} required />
                                <NumericInput
                                    {...getNumericInputProps(formik, 'VAT', monetaryScales.netVatTotal, CONSTANTS.FIXED_SCALES_VALUES.VAT)}
                                    required
                                    endContent={
                                        !isVatEditable && (
                                            <Tooltip content={t.fields.VAT.errors.notEditable} className={classes.form.fields.vatTooltip}>
                                                <Icon iconName={ICONS.UI_ICON_LOCK} />
                                            </Tooltip>
                                        )
                                    }
                                    mode={!isVatEditable ? 'view' : null}
                                />

                                <NumericInput {...getNumericInputProps(formik, 'Total', monetaryScales.netVatTotal, CONSTANTS.FIXED_SCALES_VALUES.Total)} required />

                                {isProductItemModuleActive && (
                                    <>
                                        <NumericInput {...getNumericInputProps(formik, 'ItemAmount', monetaryScales.quantity)} required />
                                        <TextInput {...getTextInputProps(formik, 'ItemUnit')} />
                                        <NumericInput {...getNumericInputProps(formik, 'ItemPrice', monetaryScales.itemPrice)} required />
                                        <TextInput {...getTextInputProps(formik, 'SellerProductId')} />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={classes.form.formActions.root}>
                            <Button variant="outlined" onClick={handleCloseModal}>
                                {t.formActions.cancelButton}
                            </Button>
                            <Button
                                onClick={formik.submitForm}
                                className={classes.form.formActions.submitButton}
                                iconButton={transactionRowDetails.isDetailsUpdating}
                                disabled={!formik.dirty || !formik.isValid}
                            >
                                {transactionRowDetails.isDetailsUpdating ? <CircularProgress /> : t.formActions.submitButton}
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
