export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }

    return {
        root: dataId,
        content: `${dataId}__content`,
        arrow: `${dataId}__arrow`,
        wrapper: `${dataId}__wrapper`,
    };
};
