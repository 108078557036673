import React from 'react';
import classNames from 'classnames';

import { Button } from '../../../../../../Button';
import Icon, { ICONS } from '../../../../../../../../components/Icon/Icon';
import { Props } from './TableExpanderButtonTypes';
import { TableRowActions } from '../../../../../../../../components/Ui/DataGrid/layout';

import './TableExpanderButton.scss';

export const TableExpanderButton: React.FC<Props> = React.memo((props) => {
    const { index, isExpanded, isHeader, onClick } = props;

    const classes = classNames('table-expander-button', {
        'table-expander-button__expanded': isExpanded,
        'table-expander-button__header': isHeader,
    });

    const icon = isHeader ? ICONS.EXPAND_ALL_24 : ICONS.CHEVRON_DOWN_24;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(index, isHeader);
        e.stopPropagation();
    };

    return (
        <TableRowActions>
            <Button className={classes} iconButton variant="text" size="sm" onClick={handleClick}>
                <Icon iconName={icon} />
            </Button>
        </TableRowActions>
    );
});
