import React, { useCallback } from 'react';

import { TableHeadCellProps } from './TableHeadCellTypes';
import { useClasses } from './hooks/useClasses';
import Icon, { ICONS } from '../../../../Icon/Icon';
import { Typography } from '../../../Typography';

import './TableHeadCell.scss';

export const TableHeadCellView: React.FC<TableHeadCellProps> = React.memo((props) => {
    const { columnName, sortDirection, onSortChange, className, children, actionsColumn, sortable, justify = 'start', renderBeforeContent, hideOverflow, ...rootProps } = props;
    const sortingIcon = sortDirection === 'asc' ? ICONS.UI_ICON_ARROW_UP : sortDirection === 'desc' ? ICONS.UI_ICON_ARROW_DOWN : ICONS.UI_ICON_SORT_DEFAULT;
    const isClickable = Boolean(children && (actionsColumn || sortable));
    const classes = useClasses({ className, actionsColumn, justify, isClickable });

    const isSortingAvailable = Boolean(sortable && columnName && onSortChange);

    const handleClickSortChange: React.MouseEventHandler<HTMLTableCellElement> = useCallback(
        (e) => {
            isSortingAvailable && onSortChange(columnName, e);
        },
        [onSortChange],
    );

    return (
        <th {...rootProps} className={classes.root}>
            {renderBeforeContent}
            <div className={classes.content.root} onClick={handleClickSortChange}>
                <Typography variant="label-sm" element="span" className={classes.content.label} hideOverflow={hideOverflow}>
                    {children}
                </Typography>
                {isSortingAvailable && <Icon iconName={sortingIcon} className={classes.sortIcon} />}
            </div>
        </th>
    );
});
