import { createDataId } from '../../../../../../../common/utils/dataId';

export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }
    return {
        root: dataId,
        createFieldName: (fieldName: string) => createDataId(dataId, 'form', 'field', fieldName),
    };
};
