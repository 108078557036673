import { InvoiceDTO } from '../../../../services/types/ApiTypes';
import { areTwoNumsEqualWithPrecision } from '../invoice-header/utils';

export const compareInvoiceTotalSum = (invoice: InvoiceDTO) => {
    if (!invoice) {
        return false;
    }
    const rowsSumsWrong = (invoice.InvoiceRowsPrice || invoice.InvoiceRowsPrice === 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsPrice, invoice.SumWithoutVat);
    const rowsVatSumsWrong = (invoice.InvoiceRowsVAT || invoice.InvoiceRowsVAT === 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsVAT, invoice.Vat);
    const rowsTotalWrong = (invoice.InvoiceRowsTotal || invoice.InvoiceRowsTotal === 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsTotal, invoice.TotalAmountWithVat);

    return rowsSumsWrong || rowsVatSumsWrong || rowsTotalWrong;
};

export const getInvoiceIssues = (invoice: InvoiceDTO) => {
    const isDuplicate = invoice?.IsDuplicate;
    const isRowsNotMatch = invoice.InvoiceRowsCount > 0 && compareInvoiceTotalSum(invoice);
    const isRowsNotMatchAndDuplicate = isDuplicate && isRowsNotMatch;
    return { isDuplicate, isRowsNotMatch, isRowsNotMatchAndDuplicate };
};
