import React, { useMemo } from 'react';

import { HeaderNotification } from '../HeaderNotification/HeaderNotification';
import { getInvoiceIssues } from '../helpers';
import { Props } from './InvoiceHeaderNotificationTypes';
import { useClasses, useDataId, useTranslations } from './hooks';

export const InvoiceHeaderNotificationView = (props: Props) => {
    const { invoice } = props;
    const dataId = useDataId();
    const classes = useClasses();
    const t = useTranslations();

    const emptyError = useMemo(() => {
        const errors = getInvoiceIssues(invoice);
        return errors.isRowsNotMatchAndDuplicate
            ? t.messages.notMatchAndDuplicate
            : errors.isDuplicate
            ? t.messages.invoiceIsDuplicate
            : errors?.isRowsNotMatch
            ? t.messages.invoiceRowsNotMatch
            : null;
    }, [invoice]);

    if (!emptyError) {
        return null;
    }

    return (
        <div className={classes.root}>
            <HeaderNotification type="alert" variant="empty" dataId={dataId?.root} title={emptyError?.title} message={emptyError?.text} />
        </div>
    );
};
