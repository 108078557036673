import withSuspense from '../../../../common/hocs/withSuspense';
import { connect } from '../../../../storeConfig';
import { GlobalState } from '../../../../rootReducer';
import TransactionRows from './TransactionRows';
import {
    getTransactionRowsList,
    updateAccount,
    updateCustomField,
    updateTransactionRowProductItemBuyer,
    updateVatCode,
    openTransactionRowDetails,
    addNewTransactionRow,
} from './TransactionRowsActions';
import { getUserVATCodes, updateCustomCostObjectiveArray } from '../../../../common/user/UserActions';
import { selectTransactionRowDetails, selectTransactionRowsIsLoading, selectTransactionRowsListLoadable } from './TransactionRowsReducers';
import { getCompanyCustomCostObjectives, getCurrentUser, selectUserActiveVatCodes } from '../../../../common/user/UserSelectors';
import { selectInvalidTransactionRowsList } from '../invoice-confirmations/InvoiceConfirmationsReducer';
import { DispatchProps, Props } from './TransactionRowsTypes';

const mapStateToProps = (
    state: GlobalState,
): Pick<Props, 'transactionRowsLoadable' | 'customCostObjectives' | 'userData' | 'transactionRowDetails' | 'invalidTransactionRows' | 'userActiveVatCodes' | 'transactionRowsIsLoading'> => ({
    transactionRowsLoadable: selectTransactionRowsListLoadable(state),
    customCostObjectives: getCompanyCustomCostObjectives(state),
    userData: getCurrentUser(state),
    transactionRowDetails: selectTransactionRowDetails(state),
    invalidTransactionRows: selectInvalidTransactionRowsList(state),
    userActiveVatCodes: selectUserActiveVatCodes(state),
    transactionRowsIsLoading: selectTransactionRowsIsLoading(state),
});

const mapDispatchToProps: DispatchProps = {
    addNewTransactionRow,
    getTransactionRowsList,
    getUserVATCodes,
    updateCustomCostObjectiveArray,
    openTransactionRowDetails,
    updateCustomField,
    updateAccount,
    updateVatCode,
    updateTransactionRowProductItemBuyer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(TransactionRows));
