import React, { useMemo } from 'react';
import { NumericInputProps } from './NumericInputTypes';
import { TextInput } from '../TextInput';
import { DECIMAL_DP_REGEXP } from '../../../../common/utils/validators';

export const NumericInputView: React.FC<NumericInputProps> = (props) => {
    const { onChange, ...textInputProps } = props;

    const value = useMemo(() => {
        const inputValue = typeof props.value === 'string' ? props.value : props.value.toLocaleString('en-US', { useGrouping: false });
        return inputValue.replace(',', '.');
    }, [props.value]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const input = e.target;
        const value = input.value;
        const isNumericValue = DECIMAL_DP_REGEXP.test(value);
        const caretPosition = input.selectionStart;

        if (isNumericValue) {
            onChange(e);
            window.requestAnimationFrame(() => {
                input.selectionStart = caretPosition;
                input.selectionEnd = caretPosition;
            });
        }
    };

    return (
        <TextInput
            {...textInputProps}
            value={value}
            onChange={handleChange}
            inputProps={{
                ...props.inputProps,
                viewModeValue: props.viewModeValue,
            }}
        />
    );
};
