import React, { useLayoutEffect, useRef, useState } from 'react';

import { TextareaProps } from './TextareaTypes';
import { Typography } from '../../Typography';
import Icon, { ICONS } from '../../../Icon/Icon';

import { InputAdornment } from '../common/InputAdornment';
import Tooltip from '../../../Tooltip/Tooltip';
import { InputBase } from '../common/InputBase';
import { useClasses } from './hooks/useClasses';
import { useDataId } from './hooks/useDataId';

import './Textarea.scss';

export const TextareaView: React.FC<TextareaProps> = (props) => {
    const {
        value,
        onChange,
        error,
        helperText,
        label,
        endContent,
        errorPlacement = 'vertical',
        placeholder,
        startContent,
        prefix,
        className,
        disabled,
        inputBaseProps = {},
        dataId,
        inputSize = 'md',
        ...textAreaProps
    } = props;
    const [focused, setFocused] = useState<boolean>(false);
    const classes = useClasses({ className, focused, disabled, inputSize });
    const dataIds = useDataId(dataId);
    const isCompactError = error && errorPlacement === 'compact';
    const isVerticalError = error && errorPlacement === 'vertical';
    const helperTextContent = isVerticalError ? error : helperText;
    const isShowEndContent = Boolean(props.endContent || isCompactError || props.max);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        onChange?.(e);
    };

    const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
        textAreaRef.current?.focus();
        setFocused(true);
    };

    const resetTextareaSize = (textarea: HTMLTextAreaElement) => {
        textarea.removeAttribute('style');
    };

    const resizeTextArea = (textarea: HTMLTextAreaElement) => {
        if (!textarea.value) {
            return resetTextareaSize(textarea);
        }
        window.requestAnimationFrame(() => {
            textarea.style.height = 'auto';
            textarea.style.minHeight = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.minHeight = `${textarea.scrollHeight}px`;
            textarea.style.overflow = 'hidden';
        });
    };

    const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = () => {
        setFocused(false);
    };

    useLayoutEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            resizeTextArea(textarea);
        }
    }, [textAreaRef, value, inputSize]);

    return (
        <div className={classes.root} data-id={dataIds?.root}>
            <InputBase
                label={label}
                error={isVerticalError || isCompactError}
                focused={focused}
                disabled={disabled}
                prefix={prefix}
                helperText={helperTextContent}
                helperTextProps={{ error: isVerticalError }}
                dataId={dataIds?.inputBase}
                inputSize={inputSize}
                {...inputBaseProps}
            >
                <div className={classes.textAreaWrapper} onClick={handleClick} data-id={dataIds?.textAreaWrapper}>
                    {startContent && <InputAdornment vertical>{startContent}</InputAdornment>}
                    <textarea
                        value={value}
                        onChange={handleChange}
                        placeholder={!focused && placeholder}
                        ref={textAreaRef}
                        onBlur={handleBlur}
                        rows={1}
                        disabled={disabled}
                        data-id={dataIds?.textAreaComponent}
                        {...textAreaProps}
                    />
                    {isShowEndContent && (
                        <InputAdornment vertical>
                            {isCompactError && (
                                <Tooltip content={error}>
                                    <span className={classes.errorContent}>
                                        <Icon name={ICONS.ALERT_FILLED} />
                                    </span>
                                </Tooltip>
                            )}
                            {props.max && (
                                <Typography variant="body-md" className={classes.counter}>
                                    {value?.length || 0}/{props.max}
                                </Typography>
                            )}
                            {endContent && endContent}
                        </InputAdornment>
                    )}
                </div>
            </InputBase>
        </div>
    );
};
