import classNames from 'classnames';

import { TransactionRowDetailsFieldNames, TransactionRowDetailsProps } from '../TransactionRowDetailsTypes';

import '../TransactionRowDetails.scss';

const rootClassName = `transaction-row-details`;

type Params = Pick<TransactionRowDetailsProps, 'isProductItemModuleActive'>;

export const useClasses = (params?: Params) => {
    const isProductItemModuleActive = params?.isProductItemModuleActive || false;

    return {
        root: classNames(rootClassName, {
            [`${rootClassName}--is-product-item-module-active`]: isProductItemModuleActive,
        }),
        header: {
            root: `${rootClassName}__header`,
            actions: `${rootClassName}__header__actions`,
        },
        form: {
            formActions: {
                root: `${rootClassName}__form__actions`,
                submitButton: `${rootClassName}__form__actions__submit-button`,
            },
            fields: {
                root: `${rootClassName}__form__fields`,
                commentAndAccountingDate: `${rootClassName}__form__fields__comment-and-accounting-date`,
                sumAndVat: `${rootClassName}__form__fields__sum-and-vat`,
                createFieldName: (fieldName: TransactionRowDetailsFieldNames) => `${rootClassName}__form__field ${rootClassName}__form__field__${fieldName}`,
                vatTooltip: `${rootClassName}__form__field__VAT__tooltip`,
            },
        },
    };
};
