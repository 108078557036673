import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createDataId } from '../../../../../../common/utils/dataId';
import { Typography } from '../../../../../../components/Ui/Typography';
import { Button } from '../../../../../../components/Ui/Button';
import Icon, { ICONS } from '../../../../../../components/Icon/Icon';
import { Typeahead } from '../../../../../../components/Ui/Inputs/Typeahead/Typeahead';
import { AccountDTO, DimensionDTO, TransactionRowDimensionDTO, VatCodeDTO } from '../../../../../../services/types/ApiTypes';
import { getAccounts, getCustomFields, getDimensions, getDimensionsName, getProductItems, getVatCodes, recalculateVat, rootClassName } from './ExpandedRowContentHelper';
import { ProductItemType, Props } from './ExpandedRowContentType';

import './ExpandedRowContent.scss';

export const ExpandedRowContent: React.FC<Props> = (props) => {
    const {
        invoiceStatus,
        isReadOnly,
        accountingDate,
        data,
        isMobile,
        isProducItemModuleActive,
        validationFields,
        onCustomFieldChange,
        onAccountFieldChange,
        onBuyerChange,
        onVatChange,
        dataId,
    } = props;

    const { t } = useTranslation();

    const fields = useMemo(() => getCustomFields(invoiceStatus, data?.TransactionRowsDimensions), [invoiceStatus, data]);

    const accountFieldError = useMemo(() => (validationFields.validateAccount && !data.Account ? t('view.general.mandatoryField') : null), [data, validationFields.validateAccount]);

    const vatFieldError = useMemo(() => (validationFields.validateVat && !data.VatCodeId ? t('view.general.mandatoryField') : null), [data, validationFields.validateVat]);

    const getCcoFieldError = useCallback(
        (field: TransactionRowDimensionDTO) => {
            return validationFields.validateCco && field.IsMandatory && !field.Dimension ? t('view.general.mandatoryField') : null;
        },
        [data, validationFields.validateCco],
    );

    const handleLoadMoreDimensions = (id: number) => (input: string, page?: number) => {
        return getDimensions(input, id, accountingDate, page || 1);
    };

    const handleLoadMoreAccounts = (input: string, page?: number) => {
        return getAccounts(input, accountingDate, page || 1);
    };

    const handleLoadMoreProductItems = (input: string, page?: number) => {
        return getProductItems(input, page || 1);
    };

    const handleLoadMoreVat = (input: string, page?: number) => {
        return getVatCodes(input, accountingDate, page || 1);
    };

    const handleDimensionChange = useCallback(
        (dimension: TransactionRowDimensionDTO) => (value: DimensionDTO) => {
            const newDimension = { ...dimension };
            newDimension.Dimension = value;
            newDimension.DimensionId = value.Id;
            onCustomFieldChange(newDimension, data);
        },
        [data],
    );

    const handleDimensionRemove = useCallback(
        (dimension: TransactionRowDimensionDTO) => () => {
            const newDimension = { ...dimension };
            newDimension.Dimension = null;
            newDimension.DimensionId = null;

            onCustomFieldChange(newDimension, data);
        },
        [data],
    );

    const handleAccountChange = useCallback((value: AccountDTO) => onAccountFieldChange(value, data), [data]);

    const handleAccountRemove = useCallback(() => onAccountFieldChange(null, data), [data]);

    const handleProductItemChange = useCallback((value: ProductItemType) => onBuyerChange(value, data), [data]);

    const handleProductItemRemove = useCallback(() => onBuyerChange(null, data), [data]);

    const handleVatChange = useCallback(
        (value: VatCodeDTO) => {
            const newRow = recalculateVat(data, value);
            onVatChange(newRow);
        },
        [data],
    );

    const handleVatRemove = useCallback(() => {
        const newRow = recalculateVat(data, null);
        onVatChange(newRow);
    }, [data]);

    const getProductItemFromInvoice = useCallback((): ProductItemType => {
        if (!data.BuyerProductId) {
            return null;
        }
        return {
            Id: data.BuyerProductId,
            Name: data.BuyerProductName,
        };
    }, [data]);

    return (
        <div className={rootClassName}>
            {isProducItemModuleActive && (
                <div className={`${rootClassName}__field-row`}>
                    {!isMobile && (
                        <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                            {t('component.transactionRows.BuyerCode')}
                        </Typography>
                    )}
                    <div className={`${rootClassName}__field-data`}>
                        <Typeahead
                            disabled={isReadOnly}
                            placeholder={t('component.transactionRows.BuyerCode')}
                            label={isMobile && t('component.transactionRows.BuyerCode')}
                            loadData={handleLoadMoreProductItems}
                            value={getProductItemFromInvoice()}
                            onSelect={handleProductItemChange}
                            onRemove={handleProductItemRemove}
                            itemToText={(c: ProductItemType) => c.Name}
                            loadingText={t('views.global.loading2')}
                        />
                    </div>
                </div>
            )}
            <div className={`${rootClassName}__field-row`}>
                {!isMobile && (
                    <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                        {t('component.transactionRows.account')}
                    </Typography>
                )}
                <div className={`${rootClassName}__field-data`}>
                    <Typeahead
                        disabled={isReadOnly}
                        placeholder={t('component.transactionRows.chooseAnAccount')}
                        label={isMobile && t('component.transactionRows.account')}
                        loadData={handleLoadMoreAccounts}
                        value={data?.Account}
                        onSelect={handleAccountChange}
                        onRemove={handleAccountRemove}
                        itemToText={(c: AccountDTO) => `${c?.Code} - ${c?.Description}`}
                        loadingText={t('views.global.loading2')}
                        errorPlacement="compact"
                        error={accountFieldError}
                    />
                </div>
            </div>
            <div className={`${rootClassName}__field-row`}>
                {!isMobile && (
                    <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                        {t('component.transactionRows.VAT')}
                    </Typography>
                )}
                <div className={`${rootClassName}__field-data`}>
                    <Typeahead
                        disabled={isReadOnly}
                        placeholder={t('component.transactionRows.chooseAVAT')}
                        label={isMobile && t('component.transactionRows.VAT')}
                        loadData={handleLoadMoreVat}
                        value={data?.VatCode}
                        onSelect={handleVatChange}
                        onRemove={handleVatRemove}
                        itemToText={(c: VatCodeDTO) => `${c?.Code} - ${c?.Description}`}
                        loadingText={t('views.global.loading2')}
                        errorPlacement="compact"
                        error={vatFieldError}
                    />
                </div>
            </div>
            {fields.map((field) => (
                <div className={`${rootClassName}__field-row`} key={field?.CustomCostObjectiveId}>
                    {!isMobile && (
                        <div className={`${rootClassName}__field-title`}>
                            <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                                {field?.CustomCostObjective?.Description}
                            </Typography>
                            {field?.IsMandatory && <span>*</span>}
                        </div>
                    )}
                    <div className={`${rootClassName}__field-data`}>
                        {field.CustomCostObjective?.DimensionsCount > 0 ? (
                            <Typeahead
                                disabled={isReadOnly}
                                placeholder={`${t('component.transactionRows.chooseADimension')} ${field?.IsMandatory ? '*' : ''}`}
                                label={isMobile && `${field?.CustomCostObjective?.Description} ${field?.IsMandatory ? '*' : ''}`}
                                loadData={handleLoadMoreDimensions(field?.CustomCostObjective.Id)}
                                value={field?.Dimension}
                                onSelect={handleDimensionChange(field)}
                                onRemove={handleDimensionRemove(field)}
                                itemToText={(c: DimensionDTO) => `${c?.Code} - ${c?.Description}`}
                                loadingText={t('views.global.loading2')}
                                errorPlacement="compact"
                                error={getCcoFieldError(field)}
                            />
                        ) : field?.Dimension ? (
                            <Typography dataId={createDataId(dataId, 'dropdown', 'dimensionsText')} className={`${rootClassName}__field-dimensions-text`} variant="body-md" element="div">
                                {getDimensionsName(field?.Dimension)}
                            </Typography>
                        ) : (
                            <Typography dataId={createDataId(dataId, 'dropdown', 'noDimensions')} className={`${rootClassName}__field-no-dimensions`} variant="body-md" element="div">
                                {t('component.transactionRows.NoDimensionsAvailable')}
                            </Typography>
                        )}
                    </div>
                </div>
            ))}
            <Button disabled={isReadOnly} className={`${rootClassName}__field-add-new`} size="sm" variant="outlined" startIcon={<Icon iconName={ICONS.PLUS_THICK_24} />}>
                {t('component.transactionRows.cco.addNew')}
            </Button>
        </div>
    );
};
