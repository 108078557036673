import React, { useCallback, useState } from 'react';
import { TooltipProps } from './TooltipTypes';
import { useClasses } from './hooks/useClasses';
import Tippy from '@tippy.js/react-v4';
import { useDataId } from './hooks/useDataId';
import Icon, { ICONS } from '../../Icon/Icon';
import { Typography } from '../Typography';

import './Tooltip.scss';

//https://atomiks.github.io/tippyjs/v6/all-props/
export const TooltipView: React.FC<TooltipProps> = (props) => {
    const { children, className, dataId, content, maxWidth = 280, onShow, onHide, ...tippyProps } = props;

    const [arrow, setArrow] = useState<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const dataIds = useDataId(dataId);
    const classes = useClasses({ className, isOpen });
    const delay = isOpen ? 0 : props.delay || 1000;

    const handleShow: TooltipProps['onShow'] = useCallback(
        (instance) => {
            onShow?.(instance);
            setTimeout(() => {
                setIsOpen(true);
            }, 0);
        },
        [props.onShow],
    );

    const handleHide: TooltipProps['onHide'] = useCallback((instance) => {
        onHide?.(instance);
        setIsOpen(false);
    }, []);

    return (
        <Tippy
            onShow={handleShow}
            onHide={handleHide}
            delay={delay}
            popperOptions={{
                modifiers: [
                    {
                        name: 'arrow',
                        options: {
                            element: arrow,
                        },
                    },
                ],
            }}
            render={(attrs) => {
                return (
                    <div {...attrs} className={classes.root} data-id={dataIds?.root} style={{ maxWidth }}>
                        <div className={classes.content} data-id={dataIds?.content}>
                            {typeof content === 'string' ? <Typography variant="body-md">{content}</Typography> : content}
                        </div>
                        <div ref={setArrow} className={classes.arrow(attrs['data-placement'])} data-id={dataIds?.arrow}>
                            <Icon iconName={ICONS.UI_ICON_TOOLTIP_ARROW} />
                        </div>
                    </div>
                );
            }}
            // do not override 'animation' prop to properly using headless component
            animation={false}
            {...tippyProps}
        >
            <div className={classes.wrapper} data-id={dataIds?.wrapper}>
                {children}
            </div>
        </Tippy>
    );
};
