import classNames from 'classnames';
import { TextareaProps } from '../TextareaTypes';

type Params = Pick<TextareaProps, 'focused' | 'className' | 'disabled' | 'inputSize'>;

const rootClassName = 'ui-textarea';

export const useClasses = (params: Params) => {
    const { className, disabled, inputSize } = params;

    return {
        root: classNames(rootClassName, `${rootClassName}--size-${inputSize}`, { [`${rootClassName}--disabled`]: disabled }, className),
        textAreaWrapper: classNames(`${rootClassName}__textarea-wrapper`),
        endContent: `${rootClassName}__end-content`,
        errorContent: `${rootClassName}__error-content`,
        counter: `${rootClassName}__counter`,
    };
};
