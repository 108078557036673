import { useEffect, useRef } from 'react';

export const useIntersectionObserver = (hasMore: boolean, loading: boolean, onLoadMore: () => void) => {
    const observerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScrollToBottom = (entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if (entry.isIntersecting && hasMore && !loading) {
                onLoadMore();
            }
        };

        const observer = new IntersectionObserver(handleScrollToBottom, {
            root: null,
            rootMargin: '10px',
            threshold: 0.1,
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMore, loading, onLoadMore]);

    return observerRef;
};
