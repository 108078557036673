import React from 'react';

import { CircularProgress } from '../../../../../../components/CircularProgress';
import { Typography } from '../../../../../../components/Ui/Typography';
import { Props } from './TypeaheadPreloaderTypes';

import './TypeaheadPreloader.scss';

export const TypeaheadPreloader = React.forwardRef<HTMLDivElement, Props>(function TypeaheadPreloader(props, ref) {
    const { text } = props;
    return (
        <div className="typeahead-preloader" ref={ref}>
            <CircularProgress />
            <Typography variant="label-lg">{text}</Typography>
        </div>
    );
});
