import classNames from 'classnames';
import { TooltipProps } from '../TooltipTypes';

const rootClassName = 'ui-tooltip';

type Params = Pick<TooltipProps, 'className'> & { isOpen: boolean };

export const useClasses = (params: Params) => {
    const { className, isOpen } = params;
    return {
        root: classNames(rootClassName, { [`${rootClassName}--is-open`]: isOpen }, className),
        content: `${rootClassName}__content`,
        arrow: (placement?: string) => classNames(`${rootClassName}__arrow`, placement && `${rootClassName}__arrow--placement-${placement}`),
        wrapper: `${rootClassName}__wrapper`,
    };
};
