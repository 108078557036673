import * as Yup from 'yup';
import { TransactionRowsDetailsFormValues } from '../TransactionRowDetailsTypes';
import { useTranslations } from './useTranslations';
import { CONSTANTS } from '../helpers/constants';

export const useValidationSchema = () => {
    const t = useTranslations();
    return Yup.object().shape<Partial<TransactionRowsDetailsFormValues>>({
        Description: Yup.string()
            .required(t.fields.Description.errors.minLength)
            .min(1, t.fields.Description.errors.minLength)
            .max(CONSTANTS.MAX_DESCRIPTION_LENGTH, t.fields.Description.errors.maxLength),
        Comment: Yup.string().max(CONSTANTS.MAX_COMMENT_LENGTH, t.fields.Comment.errors.maxLength),
        VAT: Yup.string().required(),
        Total: Yup.string().required(),
        SumWithoutVat: Yup.string().required(),
    });
};
