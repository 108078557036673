import { TransactionRowsDetailsFormValues } from './TransactionRowDetailsTypes';

export const initialFormValues: TransactionRowsDetailsFormValues = {
    VAT: '',
    AccountingDate: '',
    Comment: '',
    Description: '',
    ItemAmount: '',
    ItemPrice: '',
    ItemUnit: '',
    SellerProductId: '',
    SumWithoutVat: '',
    Total: '',
};
