import { useState, useCallback, useEffect } from 'react';
import { VariableSizeList } from 'react-window';

export type ExpandedRowState = {
    [index: number]: boolean;
};

export const useExpandedRow = (initialItemsCount: number, isDefaultExpanded: boolean, listRef: React.MutableRefObject<VariableSizeList<any>>, setPrevTop: (value: number) => void) => {
    const [expandedRows, setExpandedRows] = useState<ExpandedRowState>({});
    const [isAllRowsExpanded, setIsAllRowsExpanded] = useState<boolean>(isDefaultExpanded);
    const [isNewRowExpanded, setIsNewRowExpanded] = useState<boolean>(isDefaultExpanded);

    const toggleRow = useCallback(
        (index: number) => {
            setExpandedRows((prevState) => ({
                ...prevState,
                [index]: !prevState[index],
            }));
        },
        [isNewRowExpanded, expandedRows, isAllRowsExpanded],
    );

    const toggleAll = useCallback(() => {
        setIsAllRowsExpanded((prevState) => {
            setIsNewRowExpanded(!prevState);
            return !prevState;
        });
        setPrevTop(0);
        listRef.current.scrollTo(0);
        const newExpandedRows: ExpandedRowState = {};
        for (let i = 0; i < initialItemsCount; i++) {
            newExpandedRows[i] = !isAllRowsExpanded;
        }
        setExpandedRows(newExpandedRows);
    }, [expandedRows, isAllRowsExpanded, initialItemsCount]);

    useEffect(() => {
        const updatedExpandedRows: ExpandedRowState = {};
        for (let i = 0; i < initialItemsCount; i++) {
            if (expandedRows[i] === undefined && (isAllRowsExpanded || isNewRowExpanded)) {
                updatedExpandedRows[i] = true;
            }
        }
        setExpandedRows((prevState) => ({ ...prevState, ...updatedExpandedRows }));
    }, [initialItemsCount, isDefaultExpanded]);

    useEffect(() => {
        const isAllExpanded = Object.keys(expandedRows).every((key) => expandedRows[parseInt(key, 0)]);
        setIsAllRowsExpanded(isAllExpanded);
    }, [expandedRows]);

    return {
        expandedRows,
        toggleRow,
        toggleAll,
        isAllRowsExpanded,
    };
};
