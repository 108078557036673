import React from 'react';

import Icon from '../../../../Icon/Icon';
import { ICONS } from '../../../../Icon/Icon';
import { Typography } from '../../../Typography';
import { InputBaseProps } from './InputBaseTypes';
import { InputHelperText } from '../InputHelperText';
import { InputLabel } from '../InputLabel';
import { useClasses } from './hooks/useClasses';
import { useDataId } from './hooks/useDataId';

import './InputBaseView.scss';

export const InputBaseView: React.FC<React.PropsWithChildren<InputBaseProps>> = (props) => {
    const { children, label, error, helperText, prefix, helperTextProps = {}, inputLabelProps = {}, disabled, required, inputSize, focused, className, dataId, infoTooltip, ...rootProps } = props;
    const classes = useClasses({ className, error, prefix, disabled, required, focused });
    const dataIds = useDataId(dataId);
    const inputLabelSize = inputSize === 'md' ? 'label-lg' : 'label-md';
    return (
        <div className={classes.root} data-id={dataIds?.root} {...rootProps}>
            {label && (
                <InputLabel required={required} infoTooltip={infoTooltip} disabled={disabled} variant={inputLabelSize} dataId={dataIds?.label} {...inputLabelProps}>
                    {label}
                </InputLabel>
            )}

            <div className={classes.content.root} data-id={dataIds?.content}>
                {prefix && (
                    <Typography className={classes.prefix} element="span" variant="body-md" dataId={dataIds?.prefix}>
                        {prefix}
                    </Typography>
                )}

                <div className={classes.content.inputContainer}>{children}</div>
            </div>

            {helperText && (
                <InputHelperText error={error} startIcon={helperTextProps.error && <Icon iconName={ICONS.ALERT_FILLED} />} disabled={disabled} dataId={dataIds?.helperText} {...helperTextProps}>
                    {helperText}
                </InputHelperText>
            )}
        </div>
    );
};
