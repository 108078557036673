import React, { useCallback, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { FormikActions } from 'formik';
import { format } from 'date-fns';

import { TransactionRowDTO } from '../../../../../../../services/types/ApiTypes';
import { useMonetaryScalesFormat } from '../../../../../../../components/MonetaryPrecisionScales/hooks/useMonetaryScalesFormat';
import { selectTransactionRowDetails } from '../../../TransactionRowsReducers';
import { useCompanySettings } from '../../../../../../../common/hooks/useCompanySettings';
import { InvoiceStatus } from '../../../../../../../common/constants/appConstants';
import { setTransactionRowDetailsData, setTransactionRowDetailsModalOpenState, updateTransactionRow } from '../../../TransactionRowsActions';

import { RegisterNumericInput, RegisterTextInput, TransactionRowDetailsFieldNames, TransactionRowDetailsProps, TransactionRowsDetailsFormValues } from '../TransactionRowDetailsTypes';
import { CONSTANTS } from '../helpers';
import { useTranslations } from './useTranslations';
import { initialFormValues } from '../TransactionRowDetailsHelpers';
import { useClasses } from './useClasses';

export type Params = Pick<TransactionRowDetailsProps, 'invoiceStatus'>;

export const useTransactionRowDetails = (params: Params) => {
    const { invoiceStatus } = params;
    const [isCommentFieldVisible, setIsCommentFieldVisible] = useState(false);
    const [isAccountingDateFieldVisible, setIsAccountingDateFieldVisible] = useState(false);

    const t = useTranslations();
    const monetaryScales = useMonetaryScalesFormat();
    const transactionRowDetails = useSelector(selectTransactionRowDetails);
    const classes = useClasses();
    const { IsVatCodeManualEditingEnabled } = useCompanySettings();
    const dispatch = useDispatch();

    const isGeneralFieldsEditAllowed = [InvoiceStatus.New, InvoiceStatus.InApproval].includes(invoiceStatus);
    const isVatEditable = isGeneralFieldsEditAllowed && Boolean(Number(IsVatCodeManualEditingEnabled || 0));
    const isShowCommentField = Boolean(transactionRowDetails?.data?.Comment?.length) || isCommentFieldVisible;
    const isShowAccountingDateField = Boolean(transactionRowDetails?.data?.AccountingDate?.toString()?.length) || isAccountingDateFieldVisible;
    const isShowAccountingDateAndCommentSection = isShowCommentField || isShowAccountingDateField;

    const normalizeToEdit = (
        data: Omit<TransactionRowsDetailsFormValues, 'AccountingDate'> & {
            AccountingDate: Date;
        },
    ): TransactionRowsDetailsFormValues => {
        const { Description, Comment, SumWithoutVat, VAT, Total, ItemAmount, ItemUnit, ItemPrice, SellerProductId, AccountingDate } = data;

        return {
            Description: Description || '',
            Comment: Comment || '',
            Total: monetaryScales.netVatTotal(Total || '', {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.Total,
                fixedDecimalScale: true,
            }),
            VAT: monetaryScales.netVatTotal(VAT || '', {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.VAT,
                fixedDecimalScale: true,
            }),
            SumWithoutVat: monetaryScales.netVatTotal(SumWithoutVat || '', {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.SumWithoutVat,
                fixedDecimalScale: true,
            }),
            ItemAmount: monetaryScales.quantity(ItemAmount || '', {
                fixedDecimalScale: true,
            }),
            ItemUnit: ItemUnit || '',
            ItemPrice: monetaryScales.itemPrice(ItemPrice || '', {
                fixedDecimalScale: true,
            }),
            SellerProductId: SellerProductId || '',
            AccountingDate: AccountingDate?.toString() || '',
        };
    };

    const initialValues = useMemo((): TransactionRowsDetailsFormValues => {
        if (!transactionRowDetails.data) {
            return initialFormValues;
        }
        return normalizeToEdit(transactionRowDetails.data);
    }, [transactionRowDetails.data, transactionRowDetails.isDetailsUpdating]);

    const recalculateValues = (values: TransactionRowsDetailsFormValues, changedFieldName: TransactionRowDetailsFieldNames): TransactionRowsDetailsFormValues => {
        const newValues = { ...values };
        const { VatRate } = transactionRowDetails?.data;
        const { Total, VAT, SumWithoutVat, ItemPrice, ItemAmount } = values;
        const numericValues = {
            VAT: Number(VAT),
            Total: Number(Total),
            SumWithoutVat: Number(SumWithoutVat),
            ItemPrice: Number(ItemPrice),
            ItemAmount: Number(ItemAmount),
        };
        if (changedFieldName === 'Total') {
            numericValues.SumWithoutVat = numericValues.Total / (1 + VatRate / 100);
            numericValues.VAT = numericValues.Total - numericValues.SumWithoutVat;
            newValues.VAT = monetaryScales.netVatTotal(numericValues.VAT, {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.VAT,
                fixedDecimalScale: true,
            });
            newValues.SumWithoutVat = monetaryScales.netVatTotal(numericValues.SumWithoutVat, {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.VAT,
                fixedDecimalScale: true,
            });
            return newValues;
        }
        if (changedFieldName === 'SumWithoutVat') {
            numericValues.VAT = (numericValues.SumWithoutVat * VatRate) / 100;
            numericValues.Total = numericValues.SumWithoutVat + numericValues.VAT;
            newValues.VAT = monetaryScales.netVatTotal(numericValues.VAT, {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.VAT,
                fixedDecimalScale: true,
            });
            newValues.Total = monetaryScales.netVatTotal(numericValues.Total, {
                decimalScale: CONSTANTS.FIXED_SCALES_VALUES.VAT,
                fixedDecimalScale: true,
            });
            return newValues;
        }

        return newValues;
    };

    const getTextInputProps: RegisterTextInput = (formik, fieldName) => {
        return {
            value: formik.values[fieldName],
            name: fieldName,
            label: t.fields[fieldName].label,
            inputSize: 'sm',
            className: classes.form.fields.createFieldName(fieldName),
            error: formik.errors[fieldName],
            onChange: formik.handleChange,
            disabled: !isGeneralFieldsEditAllowed,
            errorPlacement: 'compact',
        };
    };

    const getNumericInputProps: RegisterNumericInput = useCallback(
        (formik, fieldName, monetaryScalesFN, customDecimalScale) => {
            const formatAndSetFormikValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const val = monetaryScalesFN(e.target.value, {
                    decimalScale: customDecimalScale,
                    ignoreBigIntConversion: true,
                    fixedDecimalScale: e.type === 'blur',
                });
                const values = { ...formik.values, [fieldName]: val };
                const normalized = recalculateValues(values, fieldName);
                formik.setValues(normalized);
            };

            return {
                ...getTextInputProps(formik, fieldName),
                viewModeValue: monetaryScalesFN(formik.values[fieldName], {
                    decimalScale: customDecimalScale,
                    fixedDecimalScale: true,
                    thousandSeparator: ' ',
                }),
                onBlur: formatAndSetFormikValue,
                onChange: formatAndSetFormikValue,
            };
        },
        [transactionRowDetails.data],
    );

    const handleCloseModal = useCallback(() => {
        setIsCommentFieldVisible(false);
        setIsAccountingDateFieldVisible(false);
        batch(() => {
            dispatch(setTransactionRowDetailsData(null));
            dispatch(setTransactionRowDetailsModalOpenState(false));
        });
    }, [transactionRowDetails.isDetailsUpdated]);

    const handleSubmitForm = useCallback(
        async (values: TransactionRowsDetailsFormValues & { AccountingDate: Date }, formikActions: FormikActions<TransactionRowsDetailsFormValues>) => {
            const newValues = { ...values };

            //normalize comment and hide field if no comment provided
            if (!newValues.Comment.length) {
                newValues.Comment = null;
                setIsCommentFieldVisible(false);
            }

            // check is accounting date is changed and pass 'isAccountingDateChanged' to updateTransactionRow
            // for additional api call to change "AccountingDate" as it not changes in main DTO
            const dateFormat = 'yyyy-MM-dd';
            const AccountingDates = {
                current: format(new Date(initialValues.AccountingDate || null), dateFormat),
                initial: format(new Date(newValues.AccountingDate || null), dateFormat),
            };

            const isAccountingDateChanged = AccountingDates.current !== AccountingDates.initial;
            if (!newValues.AccountingDate) {
                setIsAccountingDateFieldVisible(false);
            }
            const transactionRow: TransactionRowDTO = {
                ...transactionRowDetails.data,
                ...newValues,
            };

            try {
                await dispatch(updateTransactionRow(transactionRow, isAccountingDateChanged));
                // reset form state with new values to avoid submit with old values, happens only if update is successful
                formikActions.resetForm(values);
            } catch (e) {
                console.error(e);
            }
        },
        [transactionRowDetails.data, transactionRowDetails.isOpen, initialValues],
    );

    const handleClickAddCommentButton = () => {
        setIsCommentFieldVisible(true);
    };

    const handleClickAddAccountingDateButton = () => {
        setIsAccountingDateFieldVisible(true);
    };

    return {
        handleSubmitForm,
        handleCloseModal,
        getNumericInputProps,
        getTextInputProps,
        initialValues,
        isVatEditable,
        isShowCommentField,
        isShowAccountingDateField,
        isGeneralFieldsEditAllowed,
        handleClickAddCommentButton,
        handleClickAddAccountingDateButton,
        isShowAccountingDateAndCommentSection,
        transactionRowDetails,
    };
};
