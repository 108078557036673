import { Dispatch, MutableRefObject, SetStateAction, useCallback, useRef, useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { FixedSizeListProps, VariableSizeList } from 'react-window';

type Params = {
    height?: number;
    itemsCount?: number;
    onScrollEnd?: VoidFunction;
};

const headerHeight = 48;

type VariableSizeListWithPrivateProperties = VariableSizeList & { _getItemStyle?: (startIndex: number) => { top: number } | undefined };

export type UseVariableSizeListAdapter = {
    onScroll: FixedSizeListProps['onScroll'];
    scrollableRef: MutableRefObject<Scrollbar & HTMLDivElement>;
    onItemsRendered: FixedSizeListProps['onItemsRendered'];
    ref: (el: VariableSizeList) => void;
    top: number;
    listRef: MutableRefObject<VariableSizeListWithPrivateProperties>;
    updateSizes: (index: number, size: number) => void;
    getSize: (index: number) => number;
    height: number;
    setPrevTop: Dispatch<SetStateAction<number>>;
};

export const useVariableSizeListAdapter = (params: Params): UseVariableSizeListAdapter => {
    const { height, itemsCount, onScrollEnd } = params;
    const scrollableRef = useRef<Scrollbar & HTMLDivElement>(null);
    const listRef = useRef<VariableSizeListWithPrivateProperties | null>();
    const sizesMap = useRef<Map<number, number>>(new Map());

    const [top, setTop] = useState(0);
    const [prevTop, setPrevTop] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);

    const handleScroll: UseVariableSizeListAdapter['onScroll'] = ({ scrollOffset }) => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTop = scrollOffset;
        }
    };

    const handleItemsRendered: UseVariableSizeListAdapter['onItemsRendered'] = (props) => {
        const { visibleStopIndex } = props;
        const style = listRef.current && listRef.current._getItemStyle(props.overscanStartIndex);

        if (visibleStopIndex === itemsCount - 1) {
            onScrollEnd?.();
            setPrevTop(top);
        }
        setTop(() => {
            if (style?.top === 0) {
                return style.top;
            }
            return style?.top || prevTop || 0;
        });
    };

    const handleRef: UseVariableSizeListAdapter['ref'] = (el) => {
        listRef.current = el;
    };

    const updateSizes = useCallback(
        (index: number, size: number) => {
            const currentSize = sizesMap.current.get(index);
            if (currentSize !== size) {
                sizesMap.current.set(index, size);
                const sum = Array.from(sizesMap.current.values()).reduce((acc, value) => acc + value, 0) + headerHeight;

                if (sum < height) {
                    setMaxHeight(sum);
                } else if (sum >= height && maxHeight !== height) {
                    setMaxHeight(height);
                }

                listRef.current?.resetAfterIndex(index);
            }
        },
        [height, maxHeight],
    );

    const getSize: UseVariableSizeListAdapter['getSize'] = useCallback(
        (index: number) => {
            return sizesMap.current.get(index) || 50;
        },
        [listRef.current],
    );

    return { onScroll: handleScroll, scrollableRef, onItemsRendered: handleItemsRendered, ref: handleRef, top, listRef, height: maxHeight, updateSizes, getSize, setPrevTop };
};
