import React, { forwardRef, useCallback } from 'react';

import { Typography } from '../../../Typography';
import { InputProps } from './InputViewTypes';
import { useClasses } from './hooks/useClasses';
import { useDataId } from './hooks/useDataId';

import './InputView.scss';

export const InputView = forwardRef(function Input(props: InputProps, ref: React.Ref<HTMLInputElement>) {
    const { mode = 'view', inputSize = 'md', rootProps = {}, onClickViewMode, className, error, endAdornment, startAdornment, disabled, dataId, viewModeValue, ...inputProps } = props;

    const dataIds = useDataId(dataId);
    const classes = useClasses({ mode, className, inputSize, error, value: inputProps.value, defaultValue: inputProps.defaultValue, disabled, viewModeValue });
    const inputViewModeVariant = inputSize === 'md' ? 'body-lg' : 'body-md';
    const valueForViewMode = props.viewModeValue || props.value || props.placeholder;

    const handleFocusViewMode: React.FocusEventHandler<HTMLDivElement> = useCallback((e) => {
        e.target.click();
    }, []);

    const handleClickViewMode: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            if (mode === 'edit' || disabled) {
                return;
            }
            onClickViewMode?.(e);
        },
        [mode],
    );
    return (
        <div className={classes.root} data-id={dataIds?.root} {...rootProps} onClick={handleClickViewMode}>
            {startAdornment && startAdornment}
            <input type="text" className={classes.input.root} {...inputProps} ref={ref} disabled={disabled} />
            <div className={classes.viewMode.root} onClick={handleClickViewMode} data-id={dataIds?.view} tabIndex={0} onFocus={handleFocusViewMode}>
                <Typography variant={inputViewModeVariant} element="span" className={classes.viewMode.content} dataId={dataIds?.viewContent}>
                    {valueForViewMode}
                </Typography>
            </div>
            {endAdornment && endAdornment}
        </div>
    );
});
