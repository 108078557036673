import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactMenu, { closeMenu } from 'react-aria-menubutton';
import _ from 'lodash-es';

import { TextInput } from '../TextInput';
import { SelectOptionsView } from '../Select/components/SelectOptions/SelectOptionsView';
import { TypeaheadPreloader } from './components/TypeaheadPreloader/TypeaheadPreloader';
import { SelectOption } from '../Select/components/SelectOption';
import Icon, { ICONS } from '../../../../components/Icon/Icon';
import { useClasses } from '../Select';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';
import { TypeaheadProps, TypeaheadState } from './TypeaheadTypes';

import '../Select/Select.scss';

const initialState: TypeaheadState = {
    isMenuOpen: false,
    isEdit: false,
    isInputChanged: false,
    isSelected: false,
    value: '',
};

export const Typeahead = <T extends { Id: number | string }>({
    loadData,
    onSelect,
    onRemove,
    value,
    className,
    disabled,
    error,
    errorPlacement,
    endContent,
    inputSize,
    placeholder,
    itemToText,
    label,
    textInputProps,
    loadingText,
}: TypeaheadProps<T>) => {
    const [options, setOptions] = useState<T[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [selectInputState, setSelectInputState] = useState(initialState);

    const menuId = React.useMemo(() => _.uniqueId('text-input_'), []);
    const inputRef = useRef<HTMLInputElement>(null);

    const classes = useClasses({ className, error, errorPlacement, inputSize });
    const showPreloader = loading || hasMore;

    const closeOptions = () => {
        closeMenu(menuId);
        setHasMore(false);
        setIsMouseOver(false);
        setPage(1);
        setOptions([]);
        setSelectInputState(initialState);
    };

    const loadOptions = useCallback(
        _.debounce(async (input: string, page: number) => {
            try {
                const result = await loadData(input, page);
                setOptions((prevOptions) => [...prevOptions, ...result.Items]);
                setHasMore(result.HasCount && result.TotalCount > result.Skip + result.Take);
                setPage((prev) => prev + 1);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        }, 500),
        [loadData],
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLoading(true);
        setSelectInputState((prev) => ({ ...prev, isInputChanged: true, value }));
        setPage(1);
        setOptions([]);
        loadOptions(value, 1);
    };

    const handleCloseMenu = () => {
        closeOptions();
    };

    const handleLoadMore = () => {
        setLoading(true);
        loadOptions(selectInputState.value, page);
    };

    const selectedItemText = !value ? '' : Array.isArray(value) ? value.map((v) => itemToText(v)).join(', ') : itemToText(value);

    const observerRef = useIntersectionObserver(hasMore, loading, handleLoadMore);

    const handleToggleMenu = (wrapperState: ReactMenu.WrapperState) => {
        const { isOpen } = wrapperState;

        setSelectInputState((prev) => {
            const updatedState = {
                ...prev,
                ...{
                    isMenuOpen: isOpen,
                    isEdit: isOpen,
                    isInputChanged: false,
                    isSelected: false,
                    value: '',
                },
            };
            if (updatedState.isMenuOpen) {
                loadOptions('', 1);
            }
            if (!updatedState.isMenuOpen && value && prev.isInputChanged && !prev.isSelected && !prev.value) {
                onRemove?.();
            }
            return updatedState;
        });

        if (!isOpen) {
            setHasMore(false);
            setIsMouseOver(false);
            setPage(1);
            setOptions([]);
        }
    };

    const handleSelectOption = (id: number, event: React.SyntheticEvent) => {
        if (id !== value?.Id) {
            setOptions((prevOptions) => {
                const newDimension = prevOptions.find((e) => e.Id === id);
                setSelectInputState({ ...initialState, isSelected: true });
                onSelect(newDimension, event);

                return [];
            });
        }
        closeMenu(menuId);
    };

    const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        const isEscapeKey = e.key === 'Escape' || e?.keyCode === 27;

        if (isEscapeKey) {
            handleCloseMenu();
        }
    };

    const handleOptionsBlur = useCallback(() => {
        setIsMouseOver(false);
    }, []);

    const handleOptionsMouseOver = useCallback(() => {
        setIsMouseOver(true);
    }, []);

    const handleBlurMenuButton: React.FocusEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (inputRef.current) {
            selectInputState.isMenuOpen ? inputRef.current.focus() : inputRef.current.blur();
        }
    }, [selectInputState.isMenuOpen]);

    return (
        <ReactMenu.Wrapper id={menuId} className={classes.root} onSelection={handleSelectOption} closeOnSelection={false} onMenuToggle={handleToggleMenu} closeOnBlur={!isMouseOver}>
            <ReactMenu.Button disabled={selectInputState.isEdit || disabled} className={classes.triggerButton} onBlur={handleBlurMenuButton}>
                <TextInput
                    ref={inputRef}
                    value={selectInputState.isInputChanged ? selectInputState.value : selectedItemText}
                    focused={selectInputState.isMenuOpen}
                    error={error}
                    label={label}
                    disabled={disabled}
                    placeholder={!selectInputState.isMenuOpen ? placeholder : undefined}
                    endContent={
                        <div className={classes.endContent}>
                            {endContent && endContent}
                            {!disabled && <Icon name={ICONS.CHEVRON_DOWN_24} />}
                        </div>
                    }
                    onChange={handleInputChange}
                    inputSize={inputSize}
                    inputProps={{
                        onKeyUp: handleKeyUp,
                    }}
                    errorPlacement={errorPlacement}
                    {...textInputProps}
                />
            </ReactMenu.Button>

            <SelectOptionsView value={!!options.length && value?.Id} onBlur={handleOptionsBlur} onMouseOver={handleOptionsMouseOver}>
                {options.map((option, index) => (
                    <SelectOption key={index} value={option.Id} size="sm">
                        {itemToText(option)}
                    </SelectOption>
                ))}
                {showPreloader && <TypeaheadPreloader ref={observerRef} text={loadingText} />}
            </SelectOptionsView>
        </ReactMenu.Wrapper>
    );
};
