import { cloneDeep } from 'lodash-es';
import Big from 'big.js';

import { InvoiceStatus } from '../../../../../../common/constants/appConstants';
import { AccountDTO, DimensionDTO, PagedListContainer, SearchType, SortDirection, TransactionRowDimensionDTO, TransactionRowDTO, VatCodeDTO } from '../../../../../../services/types/ApiTypes';
import api from '../../../../../../services/ApiServices';
import { ProductItemType } from './ExpandedRowContentType';

const defaultCount = 25;

export const rootClassName = 'transaction-expanded-row';

export const recalculateVat = (row: TransactionRowDTO, vat: VatCodeDTO): TransactionRowDTO => {
    const newRow = { ...row };
    const recalculationNeeded = vat?.Code && row.VatCode && row?.VatCode?.VatRate !== vat?.VatRate;
    const vatPercentage = vat?.VatRate || 0;

    newRow.VatCodeId = vat?.Id || null;
    newRow.VatRate = vatPercentage;
    newRow.VatCode = vat || null;

    if (recalculationNeeded) {
        // if vat rates are same - no recalculation needed.
        // workaround for situations where we imported not 100% correct data and we could face problem with "one-cent-difference" later.
        const total = newRow.Total;
        const newSum = new Big(total).times(100).div(vatPercentage + 100);
        const newVat = new Big(total).minus(newSum);
        newRow.SumWithoutVat = newSum.toString();
        newRow.VAT = newVat.toString();
    }
    return newRow;
};

export const getCustomFields = (invoiceStatus: number, fields: TransactionRowDimensionDTO[]): TransactionRowDimensionDTO[] => {
    const fieldList = cloneDeep(fields);
    const invoiceStatuses = [InvoiceStatus.PendingExport, InvoiceStatus.Exported, InvoiceStatus.NotForExport];
    if (invoiceStatuses.includes(invoiceStatus)) {
        fieldList.sort((a, b) => a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo);
        return fieldList;
    }

    fieldList.sort((a, b) => {
        if (!a.CustomCostObjective || !b.CustomCostObjective) {
            return 0;
        }
        return a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo;
    });
    return fieldList;
};

export const getDimensionsName = (dimension: DimensionDTO) => {
    return dimension ? `${dimension?.Code} - ${dimension?.Description}` : '';
};

export const getDimensions = async (searchString: string, ccoId: number, accountingDate: Date, page: number): Promise<PagedListContainer<DimensionDTO>> => {
    const response = await api.user.getActiveCostObjectiveItemsForUser(
        {
            SortItems: [{ SortColumn: 'Code', SortDirection: SortDirection.Asc }],
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            PagingOptions: {
                Page: page,
                Count: defaultCount,
            },
        },
        ccoId,
        true,
        accountingDate.toString(),
    );
    return response.data;
};

export const getAccounts = async (searchString: string, accountingDate: Date, page: number): Promise<PagedListContainer<AccountDTO>> => {
    const response = await api.user.getActiveAccountsForUser(
        {
            SortItems: [{ SortColumn: 'Code', SortDirection: SortDirection.Asc }],
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            PagingOptions: {
                Page: page,
                Count: defaultCount,
            },
        },
        true,
        accountingDate.toString(),
    );

    return response.data;
};

export const getVatCodes = async (searchString: string, accountingDate: Date, page: number): Promise<PagedListContainer<VatCodeDTO>> => {
    const response = await api.user.getActiveVatCodesForUser(
        {
            SortItems: [{ SortColumn: 'Code', SortDirection: SortDirection.Asc }],
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            PagingOptions: {
                Page: page,
                Count: defaultCount,
            },
        },
        true,
        accountingDate.toString(),
    );
    return response.data;
};

export const getProductItems = async (searchString: string, page: number): Promise<PagedListContainer<ProductItemType>> => {
    const response = await api.productItem.getProductItems({
        SortItems: [{ SortColumn: 'Code', SortDirection: SortDirection.Asc }],
        Restrictions: [
            {
                Field: 'GeneralSearch',
                Value: searchString,
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        PagingOptions: {
            Page: page,
            Count: defaultCount,
        },
    });
    const newData = {
        ...response.data,
        Items: response.data.Items.map((e) => {
            return {
                Id: e.Code,
                Name: e.FullName,
            };
        }),
    };
    return newData;
};
