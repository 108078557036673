import { useTranslation } from 'react-i18next';

const rootTranslationKey = 'views.invoiceDetails.transactionRowDetailsModal';

export const useTranslations = () => {
    const { t } = useTranslation();

    return {
        modalTitle: t(`${rootTranslationKey}.modalTitle`),
        addCommentButton: t(`${rootTranslationKey}.addCommentButton`),
        addAccountingDateButton: t(`${rootTranslationKey}.addAccountingDateButton`),
        moreActionsButton: t(`${rootTranslationKey}.moreActionsButton`),
        formActions: {
            submitButton: t(`${rootTranslationKey}.formActions.submitButton`),
            cancelButton: t(`${rootTranslationKey}.formActions.cancelButton`),
        },
        fields: {
            Comment: {
                label: t(`${rootTranslationKey}.fields.comment.label`),
                errors: {
                    maxLength: 'MaxLength',
                },
            },
            AccountingDate: {
                label: t(`${rootTranslationKey}.fields.accountDate.label`),
            },
            Description: {
                label: t(`${rootTranslationKey}.fields.description.label`),
                errors: {
                    maxLength: t(`${rootTranslationKey}.fields.Description.errors.maxLength`),
                    minLength: t(`${rootTranslationKey}.fields.Description.errors.minlength`),
                },
            },
            SumWithoutVat: {
                label: t(`${rootTranslationKey}.fields.sum.label`),
            },
            VAT: {
                label: t(`${rootTranslationKey}.fields.vat.label`),
                errors: {
                    notEditable: t(`${rootTranslationKey}.fields.vat.errors.notEditable`),
                },
            },
            Total: {
                label: t(`${rootTranslationKey}.fields.total.label`),
            },
            ItemAmount: {
                label: t(`${rootTranslationKey}.fields.amount.label`),
            },
            ItemUnit: {
                label: t(`${rootTranslationKey}.fields.unit.label`),
            },
            ItemPrice: {
                label: t(`${rootTranslationKey}.fields.price.label`),
            },
            SellerProductId: {
                label: t(`${rootTranslationKey}.fields.sellerProductId.label`),
            },
        },
    };
};
