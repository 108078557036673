import React from 'react';
import classNames from 'classnames';

import { Props } from './HeaderNotificationTypes';
import { rootClassName } from './HeaderNotificationHelper';
import Icon, { ICONS } from '../../../../../components/Icon/Icon';
import { Button } from '../../../../../components/Ui/Button';
import { Typography } from '../../../../../components/Ui/Typography';

import './HeaderNotification.scss';

export const HeaderNotification: React.FC<Props> = (props) => {
    const { type, message, dataId, buttonText, disabled, variant = 'main', onClick, title } = props;

    const classes = classNames(rootClassName, `${rootClassName}--${variant}--${type}`);
    const isShowContentSection = !!message || !!title;
    return (
        <div className={classes} data-id={dataId}>
            <Icon className={`${rootClassName}__icon`} iconName={ICONS.ALERT_FILLED} />
            {isShowContentSection && (
                <div className={`${rootClassName}__content`}>
                    <Typography className={`${rootClassName}__content__title`} variant="body-md" element="span">
                        {title}
                    </Typography>
                    <Typography className={`${rootClassName}__content__message`} variant="body-md" element="span">
                        {message}
                    </Typography>
                </div>
            )}

            {!!onClick && (
                <Button className={`${rootClassName}__button`} disabled={disabled} onClick={onClick} size="sm">
                    {buttonText}
                </Button>
            )}
        </div>
    );
};
