import React from 'react';

import { useClasses } from './hooks/useClasses';
import { TableHead, TableRow, TableHeadCell, TableResizeTrigger } from '../../../layout';
import { DataGridHeadProps } from './DataGridHeadTypes';
import { useDataGridContext } from '../../../hooks/useDataGridContext';
import { useResizableContext } from '../../../contexts';
import { TableExpanderButton } from '../DataGridRow/components/TableExpanderButton/TableExpanderButton';

const DEFAULT_COLUMN_WIDTH = 70;
export const DataGridHeadView: React.FC<DataGridHeadProps> = (props) => {
    const { className } = props;
    const { columns, sorting, handleSortChange, toggleAll, height, isExpandable, isAllRowsExpanded } = useDataGridContext();

    const classes = useClasses({ className });

    const { getResizeTriggerProps, getRootProps } = useResizableContext();

    return (
        <TableHead>
            <TableRow className={classes.row} theadRow>
                {columns.map((column, index) => {
                    const sortingDirection = (sorting?.columnName === column.columnName && sorting?.direction) || null;
                    const cellContent = column.label || column.renderHeadCellContent?.(column) || null;
                    const defaultWidth = column.actionsColumn ? DEFAULT_COLUMN_WIDTH : column.width || DEFAULT_COLUMN_WIDTH;
                    const resizableProps = !column.actionsColumn ? getRootProps({ id: column.columnName, minWidth: DEFAULT_COLUMN_WIDTH, defaultWidth }) : {};
                    const resizeTriggerProps = getResizeTriggerProps();

                    return (
                        <TableHeadCell
                            key={column.columnName || `data-table-head-cell-${index}`}
                            renderBeforeContent={column.resizable && <TableResizeTrigger height={`${height}px`} {...resizeTriggerProps} />}
                            onSortChange={handleSortChange}
                            sortDirection={sortingDirection}
                            actionsColumn={column.actionsColumn}
                            sortable={column.sortable}
                            columnName={column.columnName}
                            {...column.headCellProps}
                            {...resizableProps}
                        >
                            {cellContent}
                        </TableHeadCell>
                    );
                })}
                {isExpandable && (
                    <TableHeadCell justify="end" actionsColumn>
                        {<TableExpanderButton index={-1} isExpanded={isAllRowsExpanded} isHeader onClick={toggleAll} />}
                    </TableHeadCell>
                )}
            </TableRow>
        </TableHead>
    );
};
